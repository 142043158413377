import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import styles from "./pictureArticle.style.module.scss";
import { isMobile } from "react-device-detect";
import StandardHeadline from "./elements/text/standardHeadline.component";
import { useState } from "react";
import { useEffect } from "react";

export default function PictureArticle({
  headline,
  imageFluid,
  svgUrl,
  text,
  altText,
  alignPicture = "left",
  pseudoDivDirection = "horizontal",
  description,
  className = "mb-6",
}) {
  return (
    <article
      className={`${styles.container} ${
        alignPicture === "left"
          ? styles.containerImageLeft
          : styles.containerImageRight
      } ${className}`}
    >
      <Text alignPicture={alignPicture} headline={headline} text={text} />
      {imageFluid && (
        <Picture
          pseudoDivDirection={pseudoDivDirection}
          alignPicture={alignPicture}
          altText={altText}
          description={description}
          imageFluid={imageFluid}
        />
      )}
      {svgUrl && <SVG url={svgUrl} />}
    </article>
  );
}
function SVG({ url }) {
  const [svg, setSvg] = useState(null);
  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((text) => setSvg(text));
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: svg }} />;
}

function Picture({
  imageFluid,
  altText,
  description,
  alignPicture,
  pseudoDivDirection,
}) {
  let negativeMargin = alignPicture === "left" ? styles.nml : styles.nmr;
  let pseudoDivClasses;
  if (pseudoDivDirection === "horizontal") {
    if (alignPicture === "left") {
      pseudoDivClasses = "redDiv--right";
    }
    if (alignPicture === "right") {
      pseudoDivClasses = "redDiv--left";
    }
  } else {
    if (alignPicture === "up" || alignPicture === "left") {
      pseudoDivClasses = "redDiv--up";
    } else {
      pseudoDivClasses = "redDiv--down";
    }
  }

  return (
    <div
      className={`d-flex flex-column w-100 ${negativeMargin} ${styles.image}`}
    >
      <div className={`rounded-image redDiv ${pseudoDivClasses} mb-4`}>
        <Img
          fluid={imageFluid}
          fadeIn={true}
          objectFit="cover"
          className="rounded-image redShadow--box"
          alt={altText}
        />
      </div>
      {description && (
        <small
          className={`text-muted ${
            alignPicture === "left" ? "align-self-end" : ""
          }`}
        >
          {description}
        </small>
      )}
    </div>
  );
}

function Text({ headline, text, alignPicture }) {
  let textAlignClass =
    alignPicture === "left" ? styles.textRight : styles.textLeft;

  return (
    <div className={`d-flex flex-column ${styles.text}`}>
      <div className={`${textAlignClass}`}>
        <StandardHeadline type="h3" className="mb-4">
          {headline}
        </StandardHeadline>
      </div>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

PictureArticle.propTypes = {
  headline: PropTypes.string,
  imageFluid: PropTypes.object,
  text: PropTypes.string,
  altText: PropTypes.string,
  align: PropTypes.string,
  description: PropTypes.string,
};
PictureArticle.defaultProps = {};
